body {
  margin: 0;
  font-family:   -apple-system, BlinkMacSystemFont,'Cairo', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .map_stylee{
  height: 70vh;
  border-radius: 5px;

} */

.tooltip_style{
  font-family: 'Cairo';
}

.small_text{
  font-size: small;
}
.large_text{
  font-size: medium;
}

[dir="rtl"] .textField label{
  left: auto;
  right: auto;
  transform-origin: top right;
  text-align:right;
 }