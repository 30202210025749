html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Cairo, -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  background: transparent;
  cursor: default; 
}

#root {
  width: 100%;
  height: 100%;
}

.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trails-text {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: rgb(66, 61, 63);
  font-size: 5em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity;
  overflow: hidden;
}

.trails-text > div {
  overflow: hidden;
}


/* .column{
  align-self: center;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  
}*/
.image{
  width: 350px;
  margin-left: -20px;
  margin-top: 40px;
  margin-bottom: 40px;
  /* width: 100px; */
}